@keyframes spin {
  100% { transform: rotate(360deg); }
}

body {
  font-family: futura-pt-bold,sans-serif;
  background: #222;
  color: #fff;
}

p {
  font-weight: 400;
  font-size: 1.2em;
  line-height: 1.3em;
}

.logo {
  max-width: 250px;
}

.wrapper {
  max-width: 100%;
  padding: 0px;
}

.post-title {
  display: none;
  text-align: center;
}

.post-content {
  width: 100%;
}

blockquote {
  font-size: 1.9em;
  line-height: 1.2em;
  padding: 0;
  margin: 0;
  position: relative;
  border: none;
  color: inherit;
}

blockquote:before {
  position: absolute;
  display: block;
  content: "\201D";
  top: -10px;
  left: -30px;
  font-size: 70px;
}

.flex-center {
  flex-wrap: wrap;
  align-items: center;
  display: flex;
  justify-content: center;
  &.top {
    align-items: start;
  }
}

section {
  padding-top: 5%;
  padding-bottom: 5%;
  padding-left: 10%;
  padding-right: 10%;
  &.header {
    padding-bottom: 0px;
  }
}

.narrow {
  width: 120px;
}

.center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.bold {
  font-weight: bold;
}

.small {
  font-size: 0.8em;
}

.light {
  background: #fff;
  color: #111;
}

.dark {
  background: #222;
  color: #fff;
  hr {
    background: $green;
  }
}

hr {
  flex-grow: 1;
  height: 4px;
  background: $red;
  margin-top: 22px;
  margin-bottom: 22px;
  margin-left: auto;
  margin-right: auto;
  border: none;
  text-align: center;
}

a, a:visited {
  color: $green;
  text-decoration: none;
}

.team-member {
  padding: 40px;
  width: 200px;
  img {
    padding-bottom: 20px;
  }
}

.partner-logo {
  margin: 20px;
  height: 50px;
  &.large {
    height: 120px;
  }
  &.larger {
    height: 180px;
  }
  &.smaller {
    max-width: 140px;
    height: auto;
  }
}

.photo-gallery {
  img {
    width: 90%;
    max-width: 400px;
    padding: 20px;
    height: auto;
  }
}

.contact-teaser {
  font-size: 2em;
  padding-top: 40px;
}

.bmi-infografic {
  width: 30%;
}

.hand-hover {
  content:url("/assets/photos/work/hand-no-cmd.png");
    &:hover {
      content:url("/assets/photos/work/hand-cmd.png");
    }
}

.spin-hover:hover {
  animation: spin 1s linear infinite;
}
