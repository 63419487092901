.site-header {
  background: #222;
  color: #fff;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 10px;
  border: none;
}

.site-nav {
  .page-link, .page-link:visited {
    color: white;
  }
  .page-link.active {
    color: $red;
  }
}

.site-title {
  display: none;
}

.site-nav {
  float: none;
  font-size: 1.2em;
  border-bottom: 1px solid white;
  @include media-query($on-palm) {
    background-color: #222;
    position: relative;
    text-align: left;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid white;
    border-radius: 0px;
    top: 0px;
    left: 0px;
  }
  .page-link {
    @include media-query($on-palm) {
      display: inline;
      margin-left: 0px;
      padding: 5px;
    }
  }
}

.language-selector {
  float: right;
  text-align: right;
  a {
    color: white;
    &.active {
      color: $green;
    }
  }
}