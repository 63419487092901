.footer {
  padding-top: 60px;
}

address {
  margin-bottom: 22px;
  font-style: normal;
  line-height: 1.6;
}

.logo-footer {
  max-width: 140px;
  padding-bottom: 80px;
}